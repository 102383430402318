import styled from "styled-components";

interface HeadingProps {
  text: string
}

const StyledHeading = styled.h1`
    font-family: "BebasNeueBold";
    color: #ffffff;
    font-size: 60px;
    @media screen and (max-width: 768px) {
        padding-top: 20px;
        font-size: 42px;
    }
`;

const Heading = ({ text }: HeadingProps) => {
  return <StyledHeading>{text}</StyledHeading>;
};

export default Heading;
