import React from 'react'
import styled from 'styled-components'

const IconContainer = styled.div`
  position: fixed;
  bottom: 20px; /* Adjust this value to control the distance from the bottom */
  right: 20px; /* Adjust this value to control the distance from the right */
  z-index: 999; /* Ensure the icon stays on top of other elements */
`

const IconImage = styled.img`
  width: 50px; /* Adjust the size of the icon as needed */
  height: 50px; /* Adjust the size of the icon as needed */
  border-radius: 50%; /* To make the icon circular if necessary */
`

const WhatsAppIcon = () => {
  return (
      <IconContainer>
          <a href="https://wa.me/+5491133869324" target="_blank" rel="noreferrer">
              <IconImage src="whatsapp-icon.png" alt="WhatsApp" />
          </a>
      </IconContainer>
  );
}
 
export default WhatsAppIcon;