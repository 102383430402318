import React from 'react';
import { styled } from 'styled-components';
import Heading from './components/Heading';
import ConstructionImage from "./components/ConstructionImage";
import AgencyInfo from './components/Agency/AgencyInfo';
import WhatsAppIcon from './components/WhatsappIcon';

const StyledApp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    z-index: 1;
    width: 100vw;
    height: 100vh;
`;

function App() {
  return (
      <StyledApp>
          <ConstructionImage />
          <WhatsAppIcon />
          <Container>
              <Heading text="PAGINA EN CONSTRUCCION" />
              <AgencyInfo />
          </Container>
      </StyledApp>
  );
}

export default App;
