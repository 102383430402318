import styled from "styled-components";

const StyledImage = styled.img`
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: 768px) {
        top: 0px;
        height: 80%;
    }
`;


const ConstructionImage = () => {
    return (
        <StyledImage
            srcSet="wip-large.png 1200w, wip-small.png 768w, wip-small.png 320w"
            alt="construccion"
        />
    );
};

export default ConstructionImage;