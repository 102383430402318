import React from "react";
import { styled } from "styled-components";
import AgencyLogo from "../AgencyLogo";

const StyledAgencyDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-bottom: 20px;
    @media screen and (max-width: 768px) {
        font-size: 12px;
        padding-bottom: 120px;
    }
`;

const StyledAgencyInfo = styled.p`
    font-family: "BebasNeueBook";
    font-size: 16px;
    letter-spacing: 4px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
        letter-spacing: 3px;
    }
`;

const AgencyInfo = () => {
  return (
      <StyledAgencyDiv>
          <AgencyLogo />
          <div>
              <StyledAgencyInfo>Contacto:</StyledAgencyInfo>
              <StyledAgencyInfo>info@meridianoagencia.com</StyledAgencyInfo>
              <StyledAgencyInfo>Tel.: 011 5921 - 0136</StyledAgencyInfo>
          </div>
      </StyledAgencyDiv>
  );
};

export default AgencyInfo;