import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "BebasNeueBold";
    src: local("BebasNeueBold"),
      url("./fonts/BebasNeue/BebasNeue-Bold.ttf") format("truetype");
    font-weight: bold;
  }

  @font-face {
    font-family: "BebasNeueBook";
    src: local("BebasNeueBook"),
      url("./fonts/BebasNeue/BebasNeue-Book.ttf") format("truetype");
    font-weight: normal;
  }

  body {
    font-family: "BebasNeueBook";
    background-color: #008894;
    color: #FFFFFF;
    position: relative;
    overflow: hidden;
  }
`;

export default GlobalStyles;
