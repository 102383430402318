import styled from "styled-components";

const StyledImage = styled.img`
    position: relative;
    top: 0;
    left: 0;
`;

const AgencyLogo = () => {
    return <StyledImage src="logo-meridiano.png" alt="logo-meridiano" height={100} width={150} />;
};

export default AgencyLogo;
